import React from "react";

import Typography from "@material-ui/core/Typography";

import axios from "axios";

class DetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListTransactions: [],
    };
  }

  componentDidMount() {
    //601266/60101300B010001
    let orderId = this.props.match.params.orderId;
    let rel = window.location.href.replace("http://localhost:3000/detail/", "");
    console.log(rel + "   lien0");
    console.log(orderId + "oredreid");

    var url =
      "https://webmerchant.poste.dz/payment/rest/getOrderStatus.do" +
      rel +
      "&userName=OPGI_TEST@&password=opgi_test2020";

    axios
      .get(url)
      .then((res) => {
        if (res) {
          //const data = res.data.data;
          console.log(res.data.ErrorCode + "rtest " + res.data.OrderStatus);
          const ListTransactions = res.data;
          this.setState({
            ListTransactions,
          });
        } else alert("erreur web service" + res);
      })
      .catch((error) => {
        alert(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          Details
        </Typography>
        {this.state.ListTransactions.OrderStatus === 2 ? (
          <Typography variant="h6" gutterBottom>
            Votre paiement d'un montant de{" "}
            {this.state.ListTransactions.depositAmount} DA avec la carte{" "}
            {this.state.ListTransactions.Pan} à été effectué avec succé monsieur
            :{this.state.ListTransactions.cardholderName}.
          </Typography>
        ) : (
          <Typography variant="h6" gutterBottom>
            Votre paiement n'a pas été effectué merci de refaire l'operation
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default DetailForm;
