import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_cnt: "",
      code_log: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Identification
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="Numero de contrat"
              fullWidth
              autoComplete="given-name"
              value={this.state.id_cnt}
              onChange={(value) =>
                this.setState({ id_cnt: value.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Code Logement"
              fullWidth
              autoComplete="family-name"
              value={this.state.code_log}
              onChange={(value) =>
                this.setState({ code_log: value.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12}>
            <label>* Champ obligatoire</label>
          </Grid>
        </Grid>

        <div class="makeStyles-buttons-5">
          <Link
            to={
              "/listConsultation/" +
              this.state.id_cnt +
              "/" +
              this.state.code_log
            }
          >
            <Button variant="contained" color="secondary">
              Suivant
            </Button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default AddressForm;
