import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import moment from "moment";
import { chain, round } from "mathjs";
import { Button } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListConsultation: [],
      myvalue: "",
      mount: "",

      redirection: false,
    };
  }

  componentDidMount() {
    //601266/60101300B010001
    let cnt = this.props.match.params.id_cnt
      ? this.props.match.params.id_cnt
      : "601266";
    let numlog = this.props.match.params.cod_log
      ? this.props.match.params.cod_log
      : "60101300B010001";
    var url =
      "http://localhost:3001/locataire/listConsultation/" + cnt + "/" + numlog;

    axios
      .get(url)
      .then((res) => {
        if (res) {
          //const data = res.data.data;
          console.log(res + "test");
          const ListConsultation = res.data.data;
          this.setState({ ListConsultation });
        } else alert("erreur web service" + res);
      })
      .catch((error) => {
        alert(error);
      });
  }

  handleChange = (e) =>
    this.setState({
      //this.state.ListConsultation.DER_MP: e.target.value,
      mount: e.target.value,
    });

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Transaction de Paiment
        </Typography>
        <Grid container spacing={3}>
          {this.loadFillData()}
        </Grid>
      </React.Fragment>
    );
  }

  loadFillData() {
    return this.state.ListConsultation.map((data) => {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="Nom & Prenom"
              fullWidth
              autoComplete="given-name"
              value={data.nom_oc + " " + data.prn_oc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="         الاسم واللقب        "
              fullWidth
              autoComplete="family-name"
              value={data.nom_oc_ar + " " + data.prn_oc_ar}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="address1"
              name="address1"
              label="Date de naissance"
              fullWidth
              autoComplete="shipping address-line1"
              value={data.dnais_oc}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="address1"
              name="address1"
              label="Cité"
              fullWidth
              autoComplete="shipping address-line1"
              value={data.lib_cit}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              name="address"
              label="Logements"
              fullWidth
              autoComplete="shipping address-line2"
              value={
                data.lib_cnst +
                " Logement " +
                data.lib_bat +
                " Porte " +
                data.num_log +
                " Esc " +
                data.escl_log +
                " Surface " +
                data.SURF_HAB_LOG +
                " m2"
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="city"
              name="city"
              label="Usage"
              fullWidth
              autoComplete="shipping address-level2"
              value={data.LIB_USG}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="city"
              name="city"
              label="Date Occupation"
              fullWidth
              autoComplete="shipping address-level2"
              value={data.DAT_CNT_DU}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="state"
              name="state"
              label="Fin de contrat"
              fullWidth
              value={data.DAT_CNT_AU}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="country"
              name="country"
              label="Periode de"
              fullWidth
              autoComplete="shipping country"
              value={data.DER_MP}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="country"
              name="country"
              label=" a"
              fullWidth
              autoComplete="shipping country"
              //month(moment(data.DER_MP, "DD/MM/YYYY").month() + 5)
              value={moment(data.DER_MP, "DD/MM/YYYY")
                .add(this.state.myvalue ? this.state.myvalue : 0, "months")
                .format("DD/MM/YYYY")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="zip"
              name="zip"
              label="Nb mois de retard"
              fullWidth
              autoComplete="shipping postal-code"
              value={data.nbRetard}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="mois"
              label="Mois a payer"
              fullWidth
              autoComplete="cc-csc"
              value={this.state.myvalue}
              //onChange={this.handleChange}
              onChange={(value) =>
                this.setState({ myvalue: value.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="montant"
              label="Montant"
              fullWidth
              autoComplete="cc-csc"
              value={
                this.state.myvalue
                  ? chain(data.nbRetard) < 5
                    ? round(
                        chain(data.mens.replace(",", "."))
                          .multiply(this.state.myvalue ? this.state.myvalue : 1)
                          .done(),
                        2
                      )
                    : round(
                        chain(data.mens.replace(",", "."))
                          .multiply(this.state.myvalue ? this.state.myvalue : 1)
                          .done() * 1.05,
                        2
                      )
                  : 0
                //evaluate("100" + data.mens + "+ 100")
              }
              //onChange={(value) => this.setState({ mount: value.target.value })}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid>
            <div class="makeStyles-buttons-5 ">
              <Link to="/">
                <Button variant="contained">Retour</Button>
              </Link>

              {data.nbRetard > 0 && (
                <Link
                  to={
                    "/review/" +
                    round(
                      chain(data.mens.replace(",", "."))
                        .multiply(this.state.myvalue ? this.state.myvalue : 1)
                        .done(),
                      0
                    )
                  }
                >
                  <Button variant="contained" color="secondary">
                    Suivant
                  </Button>
                </Link>
              )}
            </div>
          </Grid>
        </Grid>
      );
    });
  }
}

export default PaymentForm;
