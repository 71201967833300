import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Checkout";
import * as serviceWorker from "./serviceWorker";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
/*  <GoogleReCaptchaProvider reCaptchaKey="6LeUQaQZAAAAAI0pNgcKtnVT9z7GKI1jxF97DjYP">
      <GoogleReCaptcha onVerify={(token) => console.log(token)} />
    </GoogleReCaptchaProvider>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA*/
serviceWorker.unregister();
