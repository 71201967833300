import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { Button, ThemeProvider } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import moment from "moment";

class ReviewForm extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      AlgPostUrl: [],
      redirection: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let amount = this.props.match.params.amount;

    var date = moment().format("DD-MM-YYYY hh:mm:ss");
    console.log(this._isMounted + "date");
    var url =
      "https://webmerchant.poste.dz/payment/rest/register.do?userName=OPGI_TEST@&password=opgi_test2020&amount=" +
      amount +
      "&returnUrl=http://localhost:3000/detail/&orderNumber=" +
      date +
      "&language=en";
    axios
      .get(url)
      .then((res) => {
        if (res && this._isMounted) {
          const AlgPostUrl = res.data;
          this.setState({ AlgPostUrl });
          window.location.href = res.data.formUrl;
        } else alert("erreur web service" + res);
      })
      .catch((error) => {
        alert(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <React.Fragment>
        <Grid>
          <Link to={this.state.AlgPostUrl.formUrl}></Link>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ReviewForm;
